import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { SLUG } from './constants/path';

import styles from './app.module.scss';
import { Footer } from './components/footer/footer';

const PaymentPage = React.lazy(
  () => import('./pages/payment-page/payment-page')
);
const PaymentDetailsPage = React.lazy(
  () => import('./pages/payment-details-page/payment-details-page')
);

export const App = () => {
  return (
    <div className={styles.app}>
      <header className={styles.header}>avapay</header>
      <main className={styles.main}>
        <Routes>
          <Route path={SLUG.PAYMENT}>
            <Route index={true} element={<PaymentPage />} />
            <Route path={SLUG.ID} element={<PaymentDetailsPage />} />
          </Route>
        </Routes>
      </main>
      <Footer />
    </div>
  );
};
