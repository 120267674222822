export const SLUG = {
  PAYMENT: 'payment',
  ID: ':id',
};

export const PATH = {
  ROOT_PAGE: '/',
  PAYMENT_INITIATION_PAGE: `/${SLUG.PAYMENT}`,
  PAYMENT_DETAILS_PAGE: `/${SLUG.PAYMENT}/${SLUG.ID}`,
};
