import React from 'react';
import dayjs from 'dayjs';

import { config as appConfig } from 'app/config';

import styles from './footer.module.scss';

export const Footer: React.FC = () => {
  const year = React.useMemo(() => dayjs().year(), []);

  return (
    <footer className={styles.footer}>
      <div>
        &copy; {year} {appConfig.name}
      </div>
      <div className={styles.consent}>
        Naudodamiesi paslauga Jūs sutinkate su{' '}
        <a
          href="/Naudojimosi%20s%C4%85lygos.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Naudojimosi sąlygomis
        </a>{' '}
        ir{' '}
        <a href="/Privatumo%20politika.pdf" target="_blank" rel="noreferrer">
          Privatumo politika
        </a>
        .
      </div>
      <div className={styles.developer}>
        <a
          href="https://www.avakomp.lt/kontaktai/"
          target="_blank"
          rel="noreferrer"
        >
          Avakompas
        </a>{' '}
        | avapay
      </div>
    </footer>
  );
};
